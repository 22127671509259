"use client";

import { MainMenu } from "./MainMenu";
import TopBlueHeader from "./TopBlueHeader";
import useScreenSize from "@/lib/hooks/useScreenSize";
import { CenterHeaderPart } from "./CenterHeaderPart";

const HeaderClientWrapper = ({
  menuItems,
  cookieKeys,
  tokenCipher,
  topTierItems,
}) => {
  const {
    methods: { t },
    data: { isSmallScreen },
  } = useScreenSize({ translation: "header" });

  return (
    <header className="header">
      <TopBlueHeader t={t} isSmallScreen={isSmallScreen} />
      <CenterHeaderPart tokenCipher={tokenCipher} topTierItems={topTierItems} />
      <MainMenu
        t={t}
        megaMenu={menuItems}
        cookieKeys={cookieKeys}
        isSmallScreen={isSmallScreen}
      />
    </header>
  );
};

export default HeaderClientWrapper;

"use client";

import Cookies from "js-cookie";
import useDebounce from "./useDebounce";
import { useAppDispatch, useAppSelector } from "../store";
import { messages } from "../utils/messages";
import { useRouter } from "next-nprogress-bar";
import { useTranslation } from "@/i18n/client";
import { isValidInput } from "../utils/schemas";
import { no_products_found, search } from "../gtm";
import { handleErrorLog } from "../utils/error_logs";
import { metaInfo } from "../utils/helpers/meta_info";
import { getRedirectURL } from "@/lib/utils/listings";
import { useCallback, useEffect, useState } from "react";
import { usePathname, useSearchParams } from "next/navigation";
import { updateLastSearchedTerm } from "../store/reducers/misc";
import { searchProductsAndCategories } from "../services/product";
import {
  endCommonLoading,
  startCommonLoading,
} from "../store/reducers/uiState";
import {
  getPageType,
  handleToastError,
  searchResponseReceivedCtx,
  returnSearchRequestSentCtx,
} from "../utils/functions";
import { fireSearchRequestSentEvent } from "../utils/livesearch";

export const useSearchItems = ({ routes, afterSearch = () => {} }) => {
  const router = useRouter();
  const pathname = usePathname();
  const dispatch = useAppDispatch();
  const queryParams = useSearchParams();

  const { t } = useTranslation(["header", "common"]);

  const specialCoupon = Cookies.get("specialCoupon") || "";
  const [searchTerm, setSearchTerm] = useState("");
  const [searching, setSearching] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [suggestions, setSuggestions] = useState({});

  const { customerGroup } = useAppSelector((state) => state.globalAssets);
  
  const updateSearchTerm = useCallback((textString) => {
    setSearchTerm(textString);
    const desktop = document.getElementById("desktop-search");
    const mobile = document.getElementById("mobile-search");
    if (desktop) desktop.value = textString;
    if (mobile) mobile.value = textString;
  }, []);

  useEffect(() => {
    if (pathname !== routes.searchResults) {
      handleClearSearch();
    }
  }, [pathname]);

  const handleTitleUpdate = (query) => {
    if (pathname === routes.searchResults) {
      try {
        const ele = document.querySelector("title");
        if (ele) {
          setTimeout(() => {
            ele.textContent = metaInfo.search_result.meta_title.replace(
              "{{ QUERY }}",
              query,
            );
          }, [2000]);
        }
      } catch (err) {
        handleErrorLog({
          error: err,
          is_warning: true,
          msg: "error while update search results page title",
        });
      }
    }
  };

  const handleClearSearch = useCallback(() => {
    document.body.classList.remove("search-fixed");
    setSuggestions({});
    afterSearch();
    updateSearchTerm("");
  }, [updateSearchTerm, afterSearch]);

  const removeSuggestionBox = useCallback(
    (redirectURL) => {
      router.push(redirectURL);
      router.refresh();
      setIsFocused(false);
      handleClearSearch();
    },
    [handleClearSearch, router],
  );

  const queryToSearchItems = useCallback(
    async (e, searchString = "", enterdSearch = false, customerGroup="") => {
      const isSuggestionSearch = e && typeof e === "boolean";

      // Get search text - first try searchString, then current searchTerm
      const searchQ = searchString || searchTerm;
      const trimmedSearchQ = searchQ?.trim();

      // Start loading states
      if (isSuggestionSearch) {
        setSearching(true);
      } else {
        dispatch(startCommonLoading());
      }

      // Validate search text
      if (!trimmedSearchQ) {
        handleToastError(t("messages.error.invalid_search_query"));
        if (isSuggestionSearch) {
          setSearching(false);
        } else {
          dispatch(endCommonLoading());
        }
        return;
      }

      const { data, error } = await searchProductsAndCategories({
        searchText: trimmedSearchQ,
        specialCoupon,
        customerGroup
      });

      if (!enterdSearch) {
        search({
          search_term: trimmedSearchQ,
          number_of_results: data?.products?.total_count,
          search_type: data?.products?.items?.length
            ? "regular"
            : "no products found",
        });
        fireSearchRequestSentEvent(
          returnSearchRequestSentCtx({
            searchText: trimmedSearchQ,
            pageSize: 8,
            currentPage: 1,
            request_id: data?.products?.request_id,
          }),
        );
        searchResponseReceivedCtx({ ...data?.products });
        if (!data?.products?.items?.length) {
          no_products_found({
            page_type: getPageType(),
          });
        }
        dispatch(updateLastSearchedTerm(trimmedSearchQ));
      }

      if (error) {
        handleToastError(error);
        if (isSuggestionSearch) {
          setSearching(false);
        } else {
          dispatch(endCommonLoading());
        }
        handleTitleUpdate(trimmedSearchQ);
        return;
      }

      if (isSuggestionSearch) {
        const items = data?.products || {};
        setSuggestions({ ...items });
        setSearching(false);
        handleTitleUpdate(trimmedSearchQ);
        return;
      }

      const { redirectURL } = getRedirectURL(data, searchTerm);
      dispatch(endCommonLoading());
      removeSuggestionBox(redirectURL);
      afterSearch();
    },
    [dispatch, removeSuggestionBox, searchTerm, specialCoupon, t, afterSearch],
  );

  const handleSearchIconClick = useCallback(() => {
    if (searchTerm?.trim()) {
      queryToSearchItems(false, searchTerm,false,customerGroup);
    }
  }, [searchTerm, queryToSearchItems]);

  const handleResultLink = useCallback(
    (e) => {
      const anchor = e.target.closest("a.search-result-link");
      if (anchor) {
        e.preventDefault();
        removeSuggestionBox(anchor.getAttribute("href"));
      }
    },
    [removeSuggestionBox],
  );

  const enterKeyHandler = useCallback(
    (e) => {
      if (e.key === "Enter") {
        const searchString = e.target.value.trim();
        e.preventDefault();

        document?.activeElement?.blur();

        setSearchTerm(searchString);
        queryToSearchItems(e, searchString, true,customerGroup);
      }
    },
    [queryToSearchItems],
  );

  const handleSearchChange = useCallback((e) => {
    const searchString = e.target.value.trim();
    setSearchTerm(searchString);
    if (searchString.length > 2) {
      debouncedQuerySearch(searchString);
    } else {
      setSuggestions({});
    }
  }, [customerGroup]);

  const debouncedQuerySearch = useDebounce((searchString) => {
    if (isValidInput(searchString)) {
      queryToSearchItems(true, searchString,false,customerGroup);
    } else {
      handleToastError(messages?.error?.invalidInput);
    }
  }, 500);

  useEffect(() => {
    if (routes?.searchResults === pathname) {
      const search = queryParams.get("q");
      updateSearchTerm(search);
    }
  }, [queryParams, updateSearchTerm, pathname, routes?.searchResults]);

  return {
    data: {
      searching,
      isFocused,
      searchTerm,
      suggestions,
      isSearched: Object.keys(suggestions).length > 0,
    },
    methods: {
      t,
      router,
      dispatch,
      setIsFocused,
      setSearchTerm,
      enterKeyHandler,
      handleResultLink,
      handleSearchChange,
      handleClearSearch,
      queryToSearchItems: handleSearchIconClick,
      onSearch: queryToSearchItems,
    },
  };
};

"use client";

import dynamic from "next/dynamic";
import { memo, useState } from "react";
import CloseButton from "./CloseButton";
import { useTranslation } from "@/i18n/client";

const CouponApplied = dynamic(() => import("./CouponApplied"));
const CountDownTimer = dynamic(() => import("./CountDownTimer"));
const AutoApplyButton = dynamic(() => import("./AutoApplyButton"));
const MemoAutoApplyButton = memo(AutoApplyButton);

const StickyCouponBanner = ({
  data = {},
  cookieKeys = {},
  couponApplied = false,
}) => {
  const { t } = useTranslation(["header"]);
  const [isVisible, setIsVisible] = useState(true);
  const [bannerData, setBannerData] = useState(data);
  const [isApplied, setIsApplied] = useState(couponApplied);

  const {
    bkg_colour,
    coupon_code,
    button_text,
    font_colour,
    border_colour,
    cookies_lifetime,
    auto_apply_button,
  } = bannerData || {};

  return (
    <>
      {isVisible && (
        <div
          id="top-alert-header"
          className={`topAlertHeader ${
            bkg_colour == null
              ? "bg-gradient-to-b from-[#EFAF4F] to-[#F9D371]"
              : ""
          } ${isVisible ? "animate-fade-down" : "animate-fade-up"} ${
            border_colour == null ? "border-b border-b-tertiary-black" : ""
          }`}
          style={{
            ...(bkg_colour != null && { background: bkg_colour }),
            ...(border_colour != null && {
              borderBottom: `1px solid ${border_colour}`,
            }),
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col w-12/12">
                <div className="flex justify-between  items-center mx-auto">
                  {!isApplied ? (
                    <CountDownTimer
                      t={t}
                      data={bannerData}
                      cookieKeys={cookieKeys}
                      setIsApplied={setIsApplied}
                      setBannerData={setBannerData}
                    >
                      {auto_apply_button && (
                        <MemoAutoApplyButton
                          cookieKeys={cookieKeys}
                          couponCode={coupon_code}
                          setCouponApplied={setIsApplied}
                          btnText={button_text || t("coupon_banner.apply_code")}
                        />
                      )}
                    </CountDownTimer>
                  ) : (
                    <CouponApplied
                      t={t}
                      code={coupon_code}
                      color={font_colour}
                    />
                  )}
                </div>
              </div>
              <CloseButton
                color={font_colour}
                cookieKeys={cookieKeys}
                expiry={cookies_lifetime}
                setIsVisible={setIsVisible}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StickyCouponBanner;

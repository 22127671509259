"use client";

import Link from "next/link";
import SearchBox from "./searchBox";
import MobileNavMenu from "../MblNavMenu";
import { routes } from "@/lib/utils/routes";
import { useAppSelector } from "@/lib/store";
import TopRightHeader from "./TopRightHeader";
import { memo, useEffect, useState } from "react";
import { handleToastSuccess } from "@/lib/utils/functions";
import { useSearchItems } from "@/lib/hooks/useSearchItems";
import Touchable from "@/components/common/shared/Touchable";
import Button from "@/components/common/shared/Button/button";
import { setActiveSidebar } from "@/lib/store/reducers/uiState";
import Logo from "../../../../public/images/icons/Logo.svg?url";
import { getCustomerToken } from "@/lib/store/reducers/customer";
import { setMiniTrolley } from "@/lib/store/reducers/miniTrolley";
import ImageWithFallback from "@/components/common/ImageWithFallback";

const MemorizedMobileNavMenu = memo(MobileNavMenu);

export const CenterHeaderPart = ({ tokenCipher, topTierItems }) => {
  const customerToken = useAppSelector(getCustomerToken);
  const { activeSidebar } = useAppSelector((state) => state.uiState);
  const {
    data: { searching, isFocused, searchTerm, isSearched, suggestions },
    methods: {
      t,
      router,
      dispatch,
      setIsFocused,
      enterKeyHandler,
      handleResultLink,
      handleClearSearch,
      queryToSearchItems,
      handleSearchChange,
    },
  } = useSearchItems({
    routes,
    afterSearch: () => {
      document.body.style.overflow = "unset";
    },
  });

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [hasLoggedIn, setHasLoggedIn] = useState(!!tokenCipher);

  const openMiniTrolley = () => {
    dispatch(setMiniTrolley(true));
  };

  const openSidebar = () => {
    document.body.style.overflow = "unset";
    dispatch(setActiveSidebar(true));
  };

  useEffect(() => {
    if (activeSidebar) {
      document.body.classList.add("mobileMenu");
    } else {
      document.body.classList.remove("mobileMenu");
    }
  }, [activeSidebar]);

  useEffect(() => {
    setHasLoggedIn(() => !!customerToken);
  }, [customerToken]);

  const handleRedirect = () => {
    router.push("/"); // Replace with your destination URL
  };

  return (
    <div className="centerHeaderPart">
      <div className="container">
        <div className="row lg:h-auto">
          <div className="col w-12/12">
            <div className="flex items-center justify-between xs:pb-0 pb-[76px] relative">
              <div className="leftPart">
                <div className="relative">
                  <Button
                    icon="menu"
                    theme="iconOnly"
                    onClick={openSidebar}
                    onTouchEnd={openSidebar}
                    onTouchStart={openSidebar}
                    className="me-12 lg:hidden block p-1"
                  />
                </div>
                <Touchable
                  onTouch={handleRedirect}
                  className="flex items-center justify-center"
                >
                  <Link prefetch={false} href="/" title="GSF" className="logo">
                    <ImageWithFallback
                      alt="GSF"
                      src={Logo}
                      width={74}
                      height={74}
                      unoptimized
                      loading="eager"
                    />
                  </Link>
                </Touchable>
                <SearchBox
                  t={t}
                  routes={routes}
                  searching={searching}
                  isFocused={isFocused}
                  searchTerm={searchTerm}
                  isSearched={isSearched}
                  suggestions={suggestions}
                  setIsFocused={setIsFocused}
                  enterKeyHandler={enterKeyHandler}
                  handleResultLink={handleResultLink}
                  handleClearSearch={handleClearSearch}
                  queryToSearchItems={queryToSearchItems}
                  handleSearchChange={handleSearchChange}
                />
              </div>
              <TopRightHeader
                t={t}
                routes={routes}
                router={router}
                dispatch={dispatch}
                hasLoggedIn={hasLoggedIn}
                isDropdownOpen={isDropdownOpen}
                openMiniTrolley={openMiniTrolley}
                setIsDropdownOpen={setIsDropdownOpen}
                handleToastSuccess={handleToastSuccess}
              />
            </div>
          </div>
        </div>
        <MemorizedMobileNavMenu topTierItems={topTierItems} />
      </div>
    </div>
  );
};

"use client";

import Link from "next/link";
import Image from "next/image";
import { useEffect } from "react";
import dynamic from "next/dynamic";
import { routes } from "@/lib/utils/routes";
import CloseIcon from "@/assets/icon/cross_icon.svg?url";
import { useAppDispatch, useAppSelector } from "@/lib/store";
import { setActiveSidebar } from "@/lib/store/reducers/uiState";

const DynamicNavLinks = dynamic(() => import("./DynamicNavLinks"));
const DesktopBlueHeader = dynamic(() =>
  import("../TopBlueHeader/DesktopBlueHeader"),
);

export const MainMenu = ({ t, cookieKeys, isSmallScreen, megaMenu = [] }) => {
  const dispatch = useAppDispatch();
  const { vehicle } = useAppSelector((state) => state.vehicle);
  const { activeSidebar, activeMenuKey } = useAppSelector(
    (state) => state.uiState,
  );

  useEffect(() => {
    if (activeMenuKey && activeSidebar) {
      document.body.classList.add("megaMenu-active");
    } else {
      document.body.classList.remove("megaMenu-active");
    }

    return () => {
      document.body.classList.remove("megaMenu-active");
    };
  }, [activeMenuKey]);

  const closeSidebar = () => {
    dispatch(setActiveSidebar(false));
  };

  return (
    <div className={`mainMenu ${activeSidebar ? "active" : ""}`}>
      {activeSidebar && (
        <div className="xxs:hidden flex">
          <div className="topBlueHeader">
            <div className="container">
              <DesktopBlueHeader t={t} />
            </div>
          </div>
        </div>
      )}
      <div className="container">
        <div className="row">
          <div className="col w-12/12">
            {/* Desktop Menu */}
            <div
              tabIndex={0}
              role="button"
              onClick={closeSidebar}
              className="lg:hidden block w-auto my-[20px] me-auto cursor-pointer"
            >
              <Image
                width={32}
                height={32}
                unoptimized
                src={CloseIcon}
                alt="ArrowIcon"
                className="lg:hidden block"
              />
            </div>
            <ul className="nav-link-container">
              {megaMenu?.map((item) => {
                return (
                  <DynamicNavLinks
                    item={item}
                    vehicle={vehicle}
                    key={item.menuKey}
                    dispatch={dispatch}
                    cookieKeys={cookieKeys}
                    closeSidebar={closeSidebar}
                    activeMenuKey={activeMenuKey}
                    activeSidebar={activeSidebar}
                    isSmallScreen={isSmallScreen}
                    setActiveSidebar={setActiveSidebar}
                    hasItemChilds={item.childItems?.length > 0}
                  />
                );
              })}
              <li className="static-links mt-12">
                <Link
                  prefetch={false}
                  onClick={closeSidebar}
                  href={routes.branches}
                >
                  {t("links.store_locator")}
                </Link>
              </li>
              <li className="static-links mt-1">
                <Link
                  prefetch={false}
                  href={routes.aboutUs}
                  onClick={closeSidebar}
                >
                  {t("links.about_us")}
                </Link>
              </li>
              <li className="static-links mt-1">
                <Link
                  prefetch={false}
                  onClick={closeSidebar}
                  href={routes.contactUs}
                >
                  {t("links.contact_us")}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

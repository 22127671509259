import Cookies from "js-cookie";
import CloseIcon from "@/components/common/shared/Button/SvgIcons/close";

const CloseButton = ({ color, expiry, cookieKeys, setIsVisible }) => {
  const handleClose = () => {
    setIsVisible(false);
    const isMobile = window.matchMedia("(max-width: 767px)").matches;
    const offset = isMobile ? 99 : 118;
    const productSticky = document.getElementById("product-sticky-header");
    if (productSticky) {
      productSticky.style.marginTop = "0";
    }
    const basketStiicky = document.getElementById("basket-sticky-header");
    if (basketStiicky) {
      basketStiicky.style.top = "0";
    }
    const mobileSearchBox = document.getElementById("mobile-search-box");
    if (mobileSearchBox) {
      mobileSearchBox.style.top = `${offset}px`;
    }
    const modalStickyHeader = document.getElementById("modal-sticky-header");
    if (modalStickyHeader) {
      modalStickyHeader.style.top = "0";
    }

    if (expiry && expiry > 0) {
      const date = new Date();
      date.setTime(date.getTime() + expiry * 3600 * 1000);
      Cookies.set(cookieKeys.headerBannerClosed, 1, {
        secure: true,
        expires: date,
        sameSite: "strict",
      });
    } else {
      Cookies.set(cookieKeys.headerBannerClosed, 1, {
        secure: true,
        sameSite: "strict",
      });
    }
  };

  return (
    <div className="overlay-crossicon absolute top-1/2 -translate-y-1/2 right-[10px] xs:right-[60px]">
      <button type="button" onClick={handleClose}>
        <CloseIcon color={color} className="h-6 w-6 text-gray-500" />
      </button>
    </div>
  );
};

export default CloseButton;

"use client";

import "@/assets/css/pages/footer.css";
import Link from "next/link";
import Image from "next/image";
import NewsLetter from "./NewsLetter";
import FooterLinks from "./FooterLinks";
import { routes } from "@/lib/utils/routes";
import { useTranslation } from "@/i18n/client";
import PaymentMethod from "@/components/common/PaymentMethod";
import Logo from "../../../public/images/icons/Logo.svg?url";
import Facebook from "../../../public/images/static/Facebook.svg?url";
import Recaptcha from "../../../public/images/static/recaptcha_symbol.svg?url";

const Footer = ({ cookieKeys }) => {
  const { t } = useTranslation(["footer"]);
  return (
    <>
      <NewsLetter t={t} />
      <section className="footer-section">
        <div className="container">
          <div className="row">
            <div className="col w-12/12 flex md:flex-row flex-col mb-8">
              <div className="footer-left-part">
                <div>
                  <Link prefetch={false} href="/">
                    <Image
                      alt="GSF"
                      src={Logo}
                      width={78}
                      height={50}
                      unoptimized
                      className="xs:w-[79px] w-[64px] lg:mb-[48px] xs:mb-6"
                    />
                  </Link>
                </div>
                <div className="footer-socialmedia-box">
                  <p className="text-subtitle-2-b md:mb-[20px] mb-[8px]">
                    {t("footer.follow_us")}
                  </p>
                  <span>
                    <Link
                      rel="noopener"
                      target="_blank"
                      prefetch={false}
                      href={routes.facebook}
                      className="flex md:justify-start justify-end"
                    >
                      <Image
                        width={20}
                        height={20}
                        unoptimized
                        src={Facebook}
                        alt="Social Media Facebook"
                      />
                    </Link>
                  </span>
                </div>
              </div>
              <FooterLinks t={t} routes={routes} cookieKeys={cookieKeys} />
              <div className="footer-right-part">
                <h3 className="lg:text-headline-5 xs:text-body-text-1-b text-subtitle-4-b lg:mb-[40px] xs:mb-7 mb-[16px]">
                  {t("footer.checkout_with")}
                </h3>
                <PaymentMethod isFooter />

                <div className="custom-recaptcha mt-[7px]">
                  <div className=" w-full h-full xs:max-w-[70px] max-w-full flex xs:flex-row flex-col justify-center items-center">
                    <Image
                      width={40}
                      unoptimized
                      height="100%"
                      src={Recaptcha}
                      alt="recaptcha sumbol"
                      className="xs:w-[40px] w-[28px] xs:h-[40px] h-[28px]"
                    />
                    <div className="xs:hidden block text-minor-text-500 text-primary-black text-center mt-2">
                      <p className="leading-[6px]">reCAPTCHA</p>
                      <p>
                        <Link
                          target="_blank"
                          prefetch={false}
                          href={routes.googlePrivacy}
                        >
                          Privacy
                        </Link>
                        -
                        <Link
                          target="_blank"
                          prefetch={false}
                          href={routes.googleTerms}
                        >
                          Terms
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="xs:bg-primary-blue-30 w-full h-full xs:flex hidden flex-col justify-center pl-4 gap-[6px]">
                    <div className="flex flex-col">
                      <p className="text-body-text-13-lb">protected by </p>
                      <p className="text-body-text-13-lb ">reCAPTCHA</p>
                    </div>
                    <p className="flex items-center">
                      <Link
                        prefetch={false}
                        target="_blank"
                        href={routes.googlePrivacy}
                        className="text-minor-text-mb-1-sb font-normal"
                      >
                        Privacy
                      </Link>
                      <span className="text-minor-text-mb-1-sb">-</span>

                      <Link
                        prefetch={false}
                        target="_blank"
                        href={routes.googleTerms}
                        className="text-minor-text-mb-1-sb font-normal"
                      >
                        Terms
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col w-12/12">
              <div className="flex flex-row justify-center items-center  border-t-[1px] border-t-secondary xs:py-5 pt-6 pb-[80px]">
                <div className="flex xs:flex-row flex-col xs:min-h-[20px] min-h-[48px]">
                  <div className="flex">
                    <div className="flex items-center xs:text-body-text-3 text-minor-text-1 xs:mb-0 mb-12 xs:pl-1 pl-0">
                      {t("footer.copyright", {
                        year: new Date().getFullYear(),
                      })}
                    </div>
                    <span className="xs:inline-block text-tertiary-blue-50 text-body-text-3 mx-[10px] hidden">
                      |
                    </span>
                  </div>
                  <div className="flex items-end">
                    <span className="text-body-text-3-sb underline">
                      <Link prefetch={false} href={routes.termsConditions}>
                        {t("footer.terms_conditions")}
                      </Link>
                    </span>
                    <span className="inline-block text-tertiary-blue-50 text-body-text-3 mx-2">
                      |
                    </span>
                    <span className="text-body-text-3-sb underline xs:pl-1 pl-0">
                      <Link prefetch={false} href={routes.privacyPolicy}>
                        {t("footer.privacy")}
                      </Link>
                    </span>
                    <span className="inline-block text-tertiary-blue-50 text-body-text-3 mx-2">
                      |
                    </span>
                    <span className="text-body-text-3-sb underline xs:pl-1 pl-0">
                      <Link prefetch={false} href={routes.cookiePolicy}>
                        {t("footer.cookie_policy")}
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;

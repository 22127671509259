import dynamic from "next/dynamic";
const DesktopBlueHeader = dynamic(() => import("./DesktopBlueHeader"));

const TopBlueHeader = ({ t }) => {
  return (
    <div className="topBlueHeader">
      <div className="container min-h-[24px]">
      <DesktopBlueHeader t={t} />
      </div>
    </div>
  );
};

export default TopBlueHeader;

"use client";

import Link from "next/link";
import dynamic from "next/dynamic";
import { useAppSelector } from "@/lib/store";
import { handleLogout } from "@/lib/services/auth";
import { getPrice } from "@/lib/utils/cartMethods";
import ArrowDesktop from "@/assets/static/headerArrow.svg";
import { getCartCounts, getCartPrice } from "@/lib/store/reducers/cart";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import MobileMap from "@/components/common/shared/Button/SvgIcons/mobileMap";
import ShoppingMobileIcon from "@/components/common/shared/Button/SvgIcons/carMobileIcon";
import PersonMobileIcon from "@/components/common/shared/Button/SvgIcons/personMobile";

const CustomTooltip = dynamic(
  () => import("@/components/common/shared/ToolTip"),
  {
    ssr: false,
  },
);

const AccountTooltip = dynamic(
  () => import("@/components/common/shared/AccountTooltip"),
  {
    ssr: false,
  },
);

const Dropdown = dynamic(
  () => import("@/components/common/shared/Dropdown/dropdown"),
  {
    ssr: false,
  },
);

const TopRightHeader = ({
  t,
  routes,
  router,
  dispatch,
  hasLoggedIn,
  isDropdownOpen,
  openMiniTrolley,
  setIsDropdownOpen,
  handleToastSuccess,
}) => {
  const cartPrice = useAppSelector(getCartPrice);
  const { totalCount } = useAppSelector(getCartCounts);
  const [isMobileScreen, setIsMobileScreen] = useState(false);

  const handleLogoutClick = useCallback(() => {
    handleLogout({
      dispatch,
      onSuccess: () => {
        handleToastSuccess(t("common:messages.success.logout"));
        router.push(routes.logout);
      },
    });
  }, [t, router, dispatch]);

  const options = useMemo(() => {
    return [
      { label: t("dropdown.account"), href: routes.accountDashboard },
      { label: t("dropdown.garage"), href: routes.garage },
      { label: t("dropdown.orders"), href: routes.orders },
      { label: t("dropdown.addressBook"), href: routes.addressBook },
      { label: t("dropdown.accountInfo"), href: routes.accountInfo },
      { label: t("dropdown.newsletter"), href: routes.newsletter },
      {
        label: t("dropdown.logout"),
        onClick: handleLogoutClick,
      },
    ];
  }, [handleLogoutClick, t]);

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const openDropdown = () => {
    setIsDropdownOpen(true);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 991) {
        setIsMobileScreen(false);
      } else {
        setIsMobileScreen(true);
      }
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="right-part flex w-12/12 max-w-[200px] lg:max-w-[455px] justify-end items-start xs:gap-[18px] gap-3">
      <div className="map-toolkit right-icon-container">
        <Link
          href={routes.branches}
          prefetch={false}
          className="flex items-center"
        >
          <MobileMap />
          <span className="icon-text">Stores</span>
        </Link>
        <CustomTooltip anchorId=".map-toolkit" content="Stores" place="top" />
      </div>

      <div
        tabIndex={0}
        role="button"
        onMouseEnter={openDropdown}
        onMouseLeave={closeDropdown}
        className={`person-toolkit dropdownBox right-icon-container ${
          hasLoggedIn ? "accountArrow" : ""
        }`}
        onClick={(e) => {
          if (hasLoggedIn) e.preventDefault();
          setIsDropdownOpen(!isDropdownOpen);
        }}
      >
        <Link
          prefetch={false}
          className="lg:pe-2 flex text-body-text-3-sb lg:text-body-text-2-m text-primary-black group-hover:text-tertiary-white items-center"
          href={hasLoggedIn ? "#" : routes.signin}
          onClick={(e) => {
            if (hasLoggedIn) e.preventDefault();
          }}
        >
          <PersonMobileIcon />
          <span className="icon-text my-account-header">
            {hasLoggedIn ? t("dropdown.account") : t("signIn")}
          </span>
          {hasLoggedIn && (
            <ArrowDesktop className="lg:flex hidden lg:ms-[1px] ms-0" />
          )}
          {isDropdownOpen && (
            <AccountTooltip title={hasLoggedIn ? "My Account" : "Sign In"} />
          )}
        </Link>

        {hasLoggedIn && isDropdownOpen && (
          <Dropdown
            router={router}
            options={options}
            onOptionClick={closeDropdown}
          />
        )}
      </div>

      <div
        tabIndex={0}
        role="button"
        onClick={openMiniTrolley}
        className="right-icon-container flex cart"
      >
        <div className="relative icon-box">
          <ShoppingMobileIcon />
          <Suspense>
            <span className="number">{totalCount}</span>
          </Suspense>
        </div>

        {!isMobileScreen && (
          <Suspense>
            <p className="icon-text">
              {getPrice(
                cartPrice?.grand_total?.value,
                cartPrice?.grand_total?.currency,
              )}
            </p>
          </Suspense>
        )}
        <CustomTooltip anchorId=".cart" content="Basket" place="top" />
      </div>
    </div>
  );
};

export default TopRightHeader;

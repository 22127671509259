"use client";

import { useCallback, useRef } from "react";

export const useDebounce = (fnToDebounce, durationInMs = 500) => {
  if (isNaN(durationInMs)) {
    throw new TypeError("delay for debounce should be a number");
  }

  if (fnToDebounce == null) {
    throw new TypeError("function to debounce cannot be null");
  }

  if (typeof fnToDebounce !== "function") {
    throw new TypeError("function to debounce should be a function");
  }

  // Use useRef to store the timeout ID across renders
  const timeoutRef = useRef(null);

  // Create the debounced function
  const debouncedFn = useCallback(
    (...args) => {
      // Clear any existing timeout
      if (timeoutRef.current) clearTimeout(timeoutRef.current);

      // Set new timeout
      timeoutRef.current = setTimeout(() => {
        fnToDebounce(...args);
      }, durationInMs);
    },
    [fnToDebounce, durationInMs],
  );

  return debouncedFn;
};

export default useDebounce;

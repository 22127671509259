"use client";

import { useTranslation } from "@/i18n/client";
import { useEffect, useMemo, useState } from "react";

const useScreenSize = ({ data = [], translation = "parts" }) => {
  const { t } = useTranslation([translation]);
  const [viewMore, setViewMore] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const toggleViewMore = () => {
    setViewMore(!viewMore);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const list = useMemo(() => {
    let arr = [...data];
    if (isSmallScreen && !viewMore) {
      arr = arr.slice(0, 8);
    }
    return arr;
  }, [viewMore, isSmallScreen, data]);

  return {
    data: {
      list,
      viewMore,
      isSmallScreen,
    },
    methods: {
      t,
      toggleViewMore,
    },
  };
};

export default useScreenSize;

"use client";

import Link from "next/link";
import Cookies from "js-cookie";
import { useAppSelector } from "@/lib/store";
import { useState } from "react";
import { getCustomerToken } from "@/lib/store/reducers/customer";

const footerKeys = {
  aboutUs: {
    key: "about-us",
    title: "footer.about.title",
  },
  wayToShop: {
    key: "how-to-shop",
    title: "footer.how_to_shop.title",
  },
  customerServices: {
    key: "customer-services",
    title: "footer.customer_services.title",
  },
};

const AccordianHeader = ({
  t,
  footerKey = {},
  hasIncludedKey,
  handleAccordianClick,
}) => {
  return (
    <button
      className="flex justify-between"
      onClick={() => handleAccordianClick(footerKey.key)}
    >
      <h3 className="lg:text-headline-5 xs:text-body-text-1-b text-subtitle-4-b lg:mb-[40px] xs:mb-7 mb-[16px]">
        {t(footerKey.title)}
      </h3>
      <div className="xs:hidden block">
        <svg
          width="24"
          height="25"
          fill="none"
          viewBox="0 0 24 25"
          xmlns="http://www.w3.org/2000/svg"
          className={hasIncludedKey(footerKey.key) ? "rotate-180" : ""}
        >
          <path
            d="M6 9.16211L12 15.1621L18 9.16211"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </button>
  );
};

const FooterLinks = ({ t, routes, cookieKeys }) => {
  const token = useAppSelector(getCustomerToken);
  const [footerList, setFooterList] = useState([]);

  const hasIncludedKey = (keyName) => footerList.includes(keyName);
  const handleMakesModelsClick = () => {
    Cookies.remove(cookieKeys.new_vehicle);
    Cookies.remove(cookieKeys.manual_lookup);
    Cookies.remove(cookieKeys.selectedVehicle);
  };

  const handleAccordianClick = (key) => {
    if (hasIncludedKey(key)) {
      setFooterList(footerList.filter((item) => item !== key));
    } else {
      setFooterList((oldList) => [...oldList, key]);
    }
  };

  return (
    <div className="footer-middle-part">
      <div className="flex flex-col">
        <AccordianHeader
          t={t}
          footerKey={footerKeys.aboutUs}
          hasIncludedKey={hasIncludedKey}
          handleAccordianClick={handleAccordianClick}
        />
        <ul
          className={
            !hasIncludedKey(footerKeys.aboutUs.key)
              ? "hidden xs:flex flex-col"
              : "flex flex-col xs:mb-0 mb-12"
          }
        >
          <li className="footer-list-item">
            <Link prefetch={false} href={routes.aboutUs}>
              {t("footer.about.about_link")}
            </Link>
          </li>
          <li className="footer-list-item">
            <Link
              rel="noopener"
              target="_blank"
              prefetch={false}
              href={routes.careers}
            >
              {t("footer.about.careers")}
            </Link>
          </li>
          <li className="footer-list-item">
            <Link
              rel="noopener"
              target="_blank"
              prefetch={false}
              href={routes.corporateInfo}
            >
              {t("footer.about.corporate_info")}
            </Link>
          </li>
          <li className="footer-list-item">
            <Link prefetch={false} href={routes.sitemap}>
              {t("footer.about.sitemap")}
            </Link>
          </li>
        </ul>
      </div>
      <div className="flex flex-col">
        <AccordianHeader
          t={t}
          hasIncludedKey={hasIncludedKey}
          footerKey={footerKeys.wayToShop}
          handleAccordianClick={handleAccordianClick}
        />
        <ul
          className={
            !hasIncludedKey(footerKeys.wayToShop.key)
              ? "hidden xs:flex flex-col"
              : "flex flex-col xs:mb-0 mb-12"
          }
        >
          <li className="footer-list-item">
            <Link
              prefetch={false}
              href={routes.makesAndModels}
              onClick={handleMakesModelsClick}
            >
              {t("footer.how_to_shop.makeModel")}
            </Link>
          </li>
          <li className="footer-list-item">
            <Link prefetch={false} href={routes.deliveryInfo}>
              {t("footer.how_to_shop.home_delivery")}
            </Link>
          </li>
          <li className="footer-list-item">
            <Link prefetch={false} href={routes.clickCollect}>
              {t("footer.how_to_shop.click_collect")}
            </Link>
          </li>
          <li className="footer-list-item">
            <Link prefetch={false} href={routes.branches}>
              {t("footer.how_to_shop.store")}
            </Link>
          </li>
          <li className="footer-list-item">
            <Link prefetch={false} href={routes.knowHub}>
              {t("footer.how_to_shop.hub")}
            </Link>
          </li>
          <li className="footer-list-item">
            <Link
              target="_blank"
              prefetch={false}
              href={routes.garageEquipment}
            >
              {t("footer.how_to_shop.garage")}
            </Link>
          </li>
        </ul>
      </div>
      <div className="flex flex-col">
        <AccordianHeader
          t={t}
          hasIncludedKey={hasIncludedKey}
          footerKey={footerKeys.customerServices}
          handleAccordianClick={handleAccordianClick}
        />
        <ul
          className={
            !hasIncludedKey(footerKeys.customerServices.key)
              ? "hidden xs:flex flex-col"
              : "flex flex-col xs:mb-0 mb-12"
          }
        >
          <li className="footer-list-item">
            <Link
              prefetch={false}
              href={token ? routes.accountDashboard : routes.signin}
            >
              {t("footer.customer_services.account")}
            </Link>
          </li>
          <li className="footer-list-item">
            <Link prefetch={false} href={routes.guestReturns}>
              {t("footer.customer_services.refunds")}
            </Link>
          </li>
          <li className="footer-list-item">
            <Link prefetch={false} href={routes.klarna}>
              {t("footer.customer_services.klarna")}
            </Link>
          </li>
          <li className="footer-list-item">
            <Link prefetch={false} href={routes.contactUs}>
              {t("footer.customer_services.contact")}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FooterLinks;

import Image from "next/image";
import Amex from "../../../public/images/paymentIcon/amex.svg?url";
import Visa from "../../../public/images/paymentIcon/visa.svg?url";
import PayPal from "../../../public/images/paymentIcon/paypal.svg?url";
import Klarna from "../../../public/images/paymentIcon/klarnapink.svg?url";
import ApplePay from "../../../public/images/paymentIcon/applepay.svg?url";
import GooglePay from "../../../public/images/paymentIcon/googlepay.svg?url";
import MasterCard from "../../../public/images/paymentIcon/mastercard.svg?url";

const iconsArr = [
  { alt: "paypal", src: PayPal },
  { alt: "klarna", src: Klarna },
  { alt: "visa", src: Visa },
  { alt: "mastercard", src: MasterCard },
  { alt: "applepay", src: ApplePay },
  { alt: "goolglepay", src: GooglePay },
  { alt: "amex", src: Amex },
];

const PaymentMethod = ({
  isPDP = false,
  className = "",
  iconWidth = 70,
  isFooter = false,
  iconHeight = "auto",
}) => {
  if (isFooter) {
    const splitArray = [iconsArr.slice(0, 4), iconsArr.slice(4)];
    return splitArray.map((item, i) => (
      <div
        key={`footer-payment-${i}`}
        className={`flex ${i > 0 && "mt-[7px]"}`}
      >
        {item.map((icon) => (
          <span key={icon.alt} className="icon-container">
            <Image {...icon} width={48} unoptimized height="100%" />
          </span>
        ))}
      </div>
    ));
  }

  let classList = `xs:flex hidden max-w-[312px] mx-auto gap-2 flex-wrap justify-center ${className}`;
  if (isPDP) {
    classList = "flex mt-24 gap-1 justify-center";
  }
  return (
    <div className={classList}>
      {iconsArr.map((icon) => (
        <span key={icon.alt}>
          <Image {...icon} unoptimized width={iconWidth} height={iconHeight} />
        </span>
      ))}
    </div>
  );
};

export default PaymentMethod;

import Link from "next/link";

const MobileNavMenu = ({ topTierItems = [] }) => {
  return (
    <div className="row lg:hidden flex xs:mt-4">
      <div className="w-full pl-3">
        <ul className="flex gap-3 overflow-x-scroll w-full pb-[6px] custom-scroll">
          {topTierItems.map((item) => (
            <li
              key={item.menuKey}
              className="px-2 py-1 bg-secondary-500 text-body-text-3-sb whitespace-nowrap rounded min-h-[28px]"
            >
              <Link href={item.link} prefetch={false}>{item.name}</Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MobileNavMenu;

"use client";

import dynamic from "next/dynamic";
import { getImageProps } from "next/image";
import desktopImage from "../../../../public/images/static/newsletterBg.webp";
import mobileImage from "../../../../public/images/static/newsLetterFullBg.webp";

const NewsLetterForm = dynamic(() => import("./NewsLetterForm"), {
  ssr: false,
});

const BackgroundImage = () => {
  const common = {
    quality: 90,
    loading: "lazy",
    alt: "Newsletter",
  };

  const {
    props: { srcSet: desktop },
  } = getImageProps({
    ...common,
    width: 1440,
    height: 172,
    src: desktopImage.src,
  });

  const {
    props: { srcSet: mobile, ...rest },
  } = getImageProps({
    ...common,
    width: 450,
    height: 172,
    src: mobileImage.src,
  });

  return (
    <picture className="absolute w-full max-h-[172px] top-0">
      <source media="(max-width: 767px)" srcSet={mobile} />
      <source media="(min-width: 768px)" srcSet={desktop} />
      <img
        {...rest}
        alt={common.alt}
        className="object-cover w-full h-[186px] sm:h-[172px]"
      />
    </picture>
  );
};

const NewsLetter = ({ t }) => {
  return (
    <section className="news-letter relative xs:min-h-max min-h-[186px]">
      <BackgroundImage />
      <div className="container xs:py-[43px] py-6 h-full">
        <div className="row">
          <div className="col xs:w-6/12 w-12/12">
            <h3 className="xs:text-headline-3-xb text-headline-6  mb-[6px] text-tertiary-white">
              {t("newsletter.title")}
            </h3>
            <p className="news-letter-content">{t("newsletter.text")}</p>
          </div>
          <NewsLetterForm t={t} />
        </div>
      </div>
    </section>
  );
};

export default NewsLetter;
